<template>
  <v-row>
    <v-toolbar>
      <h1>Manging User Authentication</h1>
    </v-toolbar>
  </v-row>
</template>
<script>
export default {
  name: "auth",
};
</script>