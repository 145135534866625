<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <add-event></add-event>
    <view-event-dialog></view-event-dialog>
  </v-app>
</template>

<script>

import AddEvent from './components/addEvent.vue';
import ViewEventDialog from './components/viewEventDialog.vue';
export default {
  name: "App",
  components:{
    AddEvent,
    ViewEventDialog
  },
  data: () => ({
    //
  }),
};
</script>
