var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"25%","persistent":""},model:{value:(_vm.getEventDialog.show),callback:function ($$v) {_vm.$set(_vm.getEventDialog, "show", $$v)},expression:"getEventDialog.show"}},[_c('v-card',{attrs:{"elevation":"0","width":"100%"}},[_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.getEditEvent === false ? 'Create Meeting' : 'Edit Meeting')}}),_c('v-card-text',{staticClass:"overflow-y-auto"},[_c('v-form',[_c('v-text-field',{attrs:{"label":"Meeting Title","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-text-field',{attrs:{"label":"Location","prepend-icon":"mdi-map-marker","required":""},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('v-text-field',{attrs:{"label":"Description","prepend-icon":"mdi-calendar-text-outline"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.startTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.startTime=$event},"update:return-value":function($event){_vm.startTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[(_vm.modal1)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.startTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.endTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.endTime=$event},"update:return-value":function($event){_vm.endTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.endTime)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)],1),_c('v-autocomplete',{attrs:{"label":"Time Zone","items":_vm.timeZones},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}}),_c('v-combobox',{attrs:{"prepend-icon":"mdi-account-multiple","chips":"","multiple":""},model:{value:(_vm.attendees),callback:function ($$v) {_vm.attendees=$$v},expression:"attendees"}}),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","tile":""},on:{"click":function($event){return _vm.addEvent()}}},[_vm._v(" Submit ")]),_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":function($event){_vm.$store.commit('closeEventDialog');
            _vm.$store.commit('setEditEvent', false);}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }