<template>
  <v-dialog v-model="getEventDialog.show" width="25%" persistent>
    <v-card elevation="0" width="100%">
      <v-card-title
        v-html="getEditEvent === false ? 'Create Meeting' : 'Edit Meeting'"
      ></v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-form>
          <v-text-field
            label="Meeting Title"
            v-model="title"
            required
          ></v-text-field>
          <v-text-field
            label="Location"
            v-model="location"
            prepend-icon="mdi-map-marker"
            required
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model="description"
            prepend-icon="mdi-calendar-text-outline"
          ></v-text-field>
          <v-row>
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" scrollable> </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="startTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startTime"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal1" v-model="startTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(startTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="8">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" scrollable> </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="endTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endTime"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="endTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(endTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-autocomplete
            label="Time Zone"
            :items="timeZones"
            v-model="timeZone"
          ></v-autocomplete>
          <v-combobox
            v-model="attendees"
            prepend-icon="mdi-account-multiple"
            chips
            multiple
          ></v-combobox>
          <!-- <v-text-field label="Attendees Email Id" prepend-icon="mdi-account-multiple"></v-text-field> -->
          <v-btn color="primary" tile class="mx-5" @click="addEvent()">
            Submit
          </v-btn>
          <v-btn
            color="primary"
            tile
            @click="
              $store.commit('closeEventDialog');
              $store.commit('setEditEvent', false);
            "
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "addEvent",
  computed: {
    ...mapGetters([
      "getEventDialog",
      "getApi",
      "getEditEvent",
      "getViewEventDialog",
    ]),
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal2: false,
      modal1: false,
      title: "",
      location: "",
      description: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      attendees: "",
      timeZones: ["America/New_York", "America/Los_Angeles", "America/Phoenix"],
      timeZone: "",
      attendants: [],
    };
  },
  watch: {
    getEditEvent(newVal) {
      if (newVal) {
        console.log(this.getViewEventDialog.startDate.getHours());
        this.title = this.getViewEventDialog.name;
        this.location = this.getViewEventDialog.location;
        this.description = this.getViewEventDialog.description;
        this.attendees = this.getViewEventDialog.attendees;
        this.timeZone = this.getViewEventDialog.timeZone;
        this.startDate =
          this.getViewEventDialog.startDate.getFullYear() +
          "-" +
          (this.getViewEventDialog.startDate.getMonth() + 1) +
          "-" +
          this.getViewEventDialog.startDate.getDate();
        this.startTime =
          this.getViewEventDialog.startDate.getHours() +
          ":" +
          this.getViewEventDialog.startDate.getMinutes();

        this.endDate =
          this.getViewEventDialog.endDate.getFullYear() +
          "-" +
          (this.getViewEventDialog.endDate.getMonth() + 1) +
          "-" +
          this.getViewEventDialog.endDate.getDate();
        this.endTime =
          this.getViewEventDialog.endDate.getHours() +
          ":" +
          this.getViewEventDialog.endDate.getMinutes();
      }
    },
  },
  methods: {
    addEvent() {
      //this.$store.commit('setEditEvent', false)
      if (!this.getEditEvent) {
        console.log(this.attendees);
        if (this.attendees.length > 1) {
          this.attendees.forEach((attend) => {
            console.log(attend);
            let data = {
              email: attend,
            };
            this.attendants.push(data);
          });
        }

        var event = {
          summary: this.title,
          location: this.location,
          description: this.description,
          start: {
            dateTime: new Date(
              this.startDate + "T" + this.startTime + ":00-04:00"
            ),
            timeZone: this.timeZone,
          },
          end: {
            dateTime: new Date(this.endDate + "T" + this.endTime + ":00-04:00"),
            timeZone: this.timeZone,
          },
          attendees: this.attendants,
          reminders: {
            useDefault: true,
            // overrides: [
            //   { method: "email", minutes: 24 * 60 },
            //   { method: "popup", minutes: 10 },
            // ],
          },
        };

        var request = this.getApi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });

        request.execute((event) => {
          console.log(event);
          this.$store.dispatch("updateCalendarItems", event);
          this.$store.commit("closeEventDialog");
        });
      } else {
        this.updateEvent();
      }
    },
    updateEvent() {
      this.getApi.client.calendar.events
        .update({
          calendarId: "shubhamkashikar29@gmail.com",
          eventId: this.getViewEventDialog.id,
          alwaysIncludeEmail: false,
          sendNotifications: true,
          sendUpdates: "all",
          supportsAttachments: false,
          resource: {
            end: {
              dateTime: this.getViewEventDialog.endDate,
              timeZone: this.getViewEventDialog.timeZone,
            },
            start: {
              dateTime: this.getViewEventDialog.startDate,
              timeZone: this.getViewEventDialog.timeZone,
            },
            summary: this.title,
          },
        })
        .then(
          function (response) {
            // Handle the results here (response.result has the parsed body).
            console.log("Response", response);
            this.$store.commit("closeEventDialog");
          },
          function (err) {
            console.error("Execute error", err);
          }
        );
    },
  },
};
</script>