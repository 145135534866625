import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: undefined,
    eventDialog: {},
    calendarItems: [],
    viewEventDialog: {},
    editEvent: false
  },
  mutations: {
    setEditEvent(state, status){
      state.editEvent = status
    },
    setApi(state, api) {
      state.api = api
    },
    setEventDialog(state) {
      state.eventDialog = { show: true }
    },
    closeEventDialog(state) {
      state.eventDialog = { show: false }
    },
    setCalendarItems(state, calendarItemObj) {
      state.calendarItems.push(calendarItemObj)
    },
    openViewEventDialog(state, eventObj) {
      state.viewEventDialog = eventObj
    },
    closeViewEventDialog(state) {
      state.viewEventDialog = { show: false }
    },
  },
  actions: {
    updateCalendarItems({ commit }, calendarObj) {
      var allDay, startDate, endDate;
      if (calendarObj.start.dateTime === undefined) {
        allDay = true;
        startDate = new Date(calendarObj.start.date + "T00:00:00-04:00");
        endDate = new Date(calendarObj.start.date + "T00:00:00-04:00");
      } else {
        allDay = false;
        startDate = new Date(calendarObj.start.dateTime);
        endDate = new Date(calendarObj.end.dateTime);
      }
      let data = {
        id: calendarObj.id,
        name: calendarObj.summary,
        location: calendarObj.location,
        description: calendarObj.description,
        start: startDate,
        end: endDate,
        color: "primary",
        timed: !allDay,
        timeZone: calendarObj.start.timeZone,
        attendees: calendarObj.attendees
      };
      commit('setCalendarItems', data)
      //      this.items.push(data);
    },
    updateViewEventDialog({commit}, eventObj){
      eventObj.show = true
      commit('openViewEventDialog', eventObj)
    }
  },
  getters: {
    getApi: state => state.api,
    getEventDialog: state => state.eventDialog,
    getCalenderItems: state => state.calendarItems,
    getViewEventDialog: state => state.viewEventDialog,
    getEditEvent: state => state.editEvent
  }
})
