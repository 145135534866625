<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar>
        <h1>Working on Google Calender</h1>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/auth')">User Auth </v-btn>
      </v-toolbar>
    </v-col>
    <v-col cols="12">
      <v-card height="60" elevation="0">
        <v-row>
          <v-col cols="3" align="center">
            <v-btn
              color="primary"
              tile
              @click="handleClientLoad()"
              width="300"
              v-if="!loggedIn"
            >
              Login
            </v-btn>
          </v-col>
          <!-- <v-col cols="3" align="center">
            <v-btn
              color="primary"
              tile
              @click="showCalender = true"
              width="300"
              v-if="loggedIn"
            >
              Get Calender
            </v-btn>
          </v-col> -->
          <v-col cols="3" align="center">
            <v-btn
              color="primary"
              tile
              @click="addEvent()"
              width="300"
              v-if="loggedIn"
            >
              Add Event
            </v-btn>
          </v-col>
          <v-col cols="3" align="center">
            <v-btn
              color="primary"
              tile
              @click="logout()"
              width="300"
              v-if="loggedIn"
            >
              Logout
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" align="center">
      <v-card width="95%" elevation="0" height="700" v-if="showCalender">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            v-if="showCalender"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="getCalenderItems"
            :event-color="getEventColor"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="showEvent"
          ></v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as fb from "@/firebase";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      focus: "",
      CLIENT_ID:
        "369158369634-q6mrnk5oi9n35f8jqc6cu2n38eugn444.apps.googleusercontent.com",
      API_KEY: "AIzaSyBwWQLEz46JAB_lmaBCJRTTQX6oBPU5B2w",
      DISCOVERY_DOCS:
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      SCOPES: "https://www.googleapis.com/auth/calendar.events",
      items: [],
      events: [],
      type: "month",
      showCalender: false,
      loggedIn: false,
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  computed: {
    ...mapGetters(["getApi", "getCalenderItems"]),
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    handleClientLoad() {
      this.getApi.load("client:auth2", () => {
        console.log("loaded client");
        this.getApi.client.init({
          apiKey: this.API_KEY,
          clientId: this.CLIENT_ID,
          scope: this.SCOPES,
        });

        // this.getApi.client.load("calender", "v3", (response) =>
        //   console.log("What is happening", response)
        // );

        this.getApi.client
          .load(
            "https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest"
          )
          .then(() => {
            console.log("Gapi Api Loaded");
          });

        const provider = new fb.firebase.auth.GoogleAuthProvider();
        provider.addScope("https://www.googleapis.com/auth/calendar.events");
        fb.firebase
          .auth()
          .signInWithPopup(provider)
          .then((response) => {
            console.log("Do you have anything", response);
            this.loggedIn = true;
            this.showCalender = true;
            this.getCalenderData();
          });

        // this.getApi.auth2
        //   .getAuthInstance()
        //   .signIn()
        //   .then(() => {
        //     this.loggedIn = true;
        //     this.getCalenderData();
        //   });
      });
    },
    getCalenderData() {
      try {
        if (this.loggedIn) {
          this.getApi.client.calendar.events
            .list({
              calendarId: "primary",
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 100,
              orderBy: "startTime",
            })
            .then((response) => {
              response.result.items.forEach((item) => {
                console.log(item);
                this.$store.dispatch("updateCalendarItems", item);
                //this.getCalenderInfo(item);
              });
              this.showCalender = true;
            });
        } else {
          console.log("Please Log in to Continue");
        }
      } catch (e) {
        console.log("Something is wrong", e.message);
      }
    },
    getCalenderInfo(item) {
      console.log(item);
      var allDay, startDate, endDate;
      if (item.start.dateTime === undefined) {
        allDay = true;
        startDate = new Date(item.start.date + "T00:00:00-04:00");
        endDate = new Date(item.start.date + "T00:00:00-04:00");
      } else {
        allDay = false;
        startDate = new Date(item.start.dateTime);
        endDate = new Date(item.end.dateTime);
      }
      let data = {
        name: item.summary,
        start: startDate,
        end: endDate,
        color: "primary",
        timed: !allDay,
        hello: "hello",
      };
      this.items.push(data);
    },
    addEvent() {
      this.$store.commit("setEventDialog");
    },
    logout() {
      location.reload();
      console.log("logged out");
      this.loggedIn = false;
    },
    showEvent({ event }) {
      console.log("The Clicked Event", event);
      this.$store.dispatch("updateViewEventDialog", {
        id: event.id,
        name: event.name,
        startDate: event.start,
        endDate: event.end,
        timeZone: event.timeZone,
        location: event.location,
        description: event.description,
        attendees: event.attendees,
      });
    },
  },
  mounted() {
    this.$store.commit("setApi", window.gapi);
    var date = new Date();
    console.log(date.getMonth() + " " + date.getFullYear());
  },
};
</script>
