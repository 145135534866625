<template>
  <v-dialog v-model="getViewEventDialog.show" width="30%">
    <v-card elevation="0" width="100%">
      <v-card-title
        class="primary white--text">
         Meeting Details
        <v-spacer></v-spacer>
        <v-icon class="px-3" color="white" @click="editEvent()">mdi-pencil</v-icon>
        <v-icon @click="deleteEvent()" color="white">mdi-delete </v-icon>
      </v-card-title>
      <v-card-text>{{getViewEventDialog.name}}</v-card-text>
      <v-card-text>{{getViewEventDialog.startDate}}</v-card-text>
      <v-card-text>{{getViewEventDialog.endDate}}</v-card-text>
      <v-card-text>{{getViewEventDialog.timeZone}}</v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "viewEventDialog",
  computed: {
    ...mapGetters(["getViewEventDialog", "getApi", "getCalenderItems"]),
  },
  data() {
    return {};
  },
  methods: {
    deleteEvent() {
      console.log(this.getViewEventDialog.id);
      //this.getApi.client.service.events.delete('primary',this.getViewEventDialog.event.id.split("=")[1])
      this.getApi.client.calendar.events
        .delete({
          calendarId: "shubhamkashikar29@gmail.com",
          eventId: this.getViewEventDialog.id,
          sendUpdates: "none",
        })
        .then((response) => {
          console.log("Response", response);
          this.getCalenderItems.forEach((element) => {
            console.log(element);
            if (element.id === this.getViewEventDialog.id) {
              this.getCalenderItems.splice(
                this.getCalenderItems.indexOf(element),
                1
              );
            }
          });
          this.$store.commit("closeViewEventDialog");
        });
    },
    editEvent(){
      this.$store.commit("setEditEvent", true);
      this.$store.commit("setEventDialog");
    }
  },
};
</script>