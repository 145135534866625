import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let firebaseConfig = {
    apiKey: "AIzaSyCKNYgDMKvgeOcFAm9BNiMs2aS5ELqiaOc",
    authDomain: "ottawa-9de56.firebaseapp.com",
    databaseURL: "https://ottawa-9de56.firebaseio.com",
    projectId: "ottawa-9de56",
    storageBucket: "ottawa-9de56.appspot.com",
    messagingSenderId: "997008308567",
    appId: "1:997008308567:web:ffd7d44234cd90ecdce612",
    measurementId: "G-LSSGDKYXLC"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

export {
    db,
    auth,
    firebase,
}